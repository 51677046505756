.ContentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    /* height: 60rem; */
    height: auto !important;
    min-height: 20rem;
    width: 65rem;
    outline: none;
    transform: translate(-50%, -50%);
    background: var(--app-white);
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.ContentModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    /* z-index: 1; */
    z-index:7;
}