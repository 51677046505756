.App {
    text-align: center;
    width: var(--app-width);
    height: 100vh;
    margin: auto;
    /*overflow: hidden;*/
  }
/* styles for custom alerts - success*/

.Toastify__toast--success {
  border: 3px solid #0f7d3e !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  background: white !important;
}

.Toastify__close-button--light {
  color: #000 !important;
  opacity: 1 !important;
  background-color: #0f7d3e !important;
  width: 2.5rem !important;
  height: 6rem !important;
}
.Toastify__toast {
  padding: 0rem !important;
}
.Toastify__close-button > svg {
  fill: white !important;
}

.Toastify__toast-theme--light {
  color: #0f7d3e !important;
  font-family: Toyota Type Regular !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: initial;
}
.Toastify__toast-theme--light span {
  color: #2c353b !important;
}

.Toastify__toast-icon {
  margin-inline-end: 20px !important;
}

.Toastify__toast-container--top-center {
  top: 0rem !important;
}

/*error*/
.Toastify__toast--error {
  border: 3px solid #bc0000 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  background: white !important;
}
.Toastify__toast--error > div {
  color: #bc0000 !important;
  font-family: Toyota Type Regular !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: initial;
}

.Toastify__toast--error > button {
  color: #000 !important;
  opacity: 1 !important;
  background-color: #bc0000 !important;
  width: 2.5rem !important;
  height: 6rem !important;
}

.Toastify__toast-container {
  padding: 0px !important;
}

  
  
