.MenuContainer {
    background: var(--app-white);
    border-radius: 0 0 .4rem .4rem;
    width: 12.2rem;
    /* min-height: 13.2rem; */
    box-shadow: 0 .4rem 1.2rem rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.MenuItem {
    min-height: 3.2rem;
    width: 100%;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: .8rem;
    padding-top: .5rem;
}

.MenuItem::selection {
    background: none;
}

.MenuItem:hover {
    background: var(--ct-blue-secondary-op-light);
    cursor: pointer;
}

.MenuItem:hover:first-child {
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
}

.MenuItem:hover:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}
.FilterModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index:7;
}

.FilterModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--width-large-screnn);
    height: calc(var(--width-large-screnn) * 1 / 2);
    background: var(--app-white);
    padding: 3rem 4rem;
    outline: none;
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    
}

@media only screen and (max-width: 100em) {
    .FilterModal {
        width: var(--width-small-screen);
        height: calc(var(--width-small-screen) * 1 / 2);
    }
}

.FilterTitleHeading {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--toyota-red-primary);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    height: 5rem;
    width: 100%;
    align-items: center;
}
.FilterTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.FiltersContainer{
    height: 100%;
    width: 100%;
    margin-bottom: 14rem;
    font-size: 1.4rem;
}