.CardContainer {
    padding: 2rem;

    width: calc(1 / 3 * var(--app-width) - 4.9rem);
    height: calc(1 / 9 * (var(--app-width) - 14rem));

    display: flex;
    align-items: flex-start;
    border: .2rem solid var(--tme-grey-5-greys);
    border-radius: .2rem;
    cursor: pointer;
}

.CardContainerDisabled {
    padding: 2rem;

    width: calc(1 / 3 * var(--app-width) - 4.9rem);
    height: calc(1 / 9 * (var(--app-width) - 14rem));

    display: flex;
    align-items: flex-start;
    border: .2rem solid var(--tme-grey-5-greys);
    border-radius: .2rem;
    cursor: pointer;
    opacity:0.6;
    pointer-events: none;
}

.CardContainer.CardSelected {
    border-color: var(--ct-blue-secondary);
    border-width: 0.2rem;
}

.Icon {
    margin-top: .4rem;
    margin-right: 2rem;
    fill: var(--tme-grey-3-greys);
}

.TitleDescriptionCombo {
    width: 80%;
    height: 80%;
}

.Title {
    color: var(--ct-blue-secondary);
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: .5rem;
}

.Description {
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    height: 50%;
}

.TitleDisabled {
    color: var(--ct-blue-secondary-op-med-light);
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: .5rem;
}

.DescriptionDisabled {
    color: var(--tme-grey-4-greys);
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
    height: 50%;
}

.IconDisabled {
    margin-top: .4rem;
    margin-right: 2rem;
    fill: var(--tme-grey-4-greys);
}