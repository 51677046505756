.ManageContentModule {
    background-color: var(--app-white);

    height: calc(100% - var(--content-header-height) + 1rem);
    border-radius: .5rem;
    box-shadow: 0 .8rem .8rem rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.AssociatedTemplates {
    max-width: 20rem;
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.AssociatedCampaigns {
    max-width: 20rem;
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}