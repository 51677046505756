.Status {
    border-radius: .3rem;
    padding: .7rem 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.38rem;
    width: 8.9rem;
    min-height: 2.9rem;
}

.Blue {
    background: var(--ct-blue-light-secondary);
}

.Yellow {
    background: #FFEBB3;
}

.Orange {
    background: var(--ct-orange-light-secondary);
}

.Green {
    background: #E1FBF3;
}

.Red {
    background: var(--ct-red-light-secondary);
}