
.DateRangeTitle {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--toyota-red-primary);
    margin-bottom: 2rem;
    align-self: flex-start;
}

.DateRangePickerWrapper {
    border: .1rem solid var(--tme-grey-6-greys);
    flex-grow: 1;
    align-self: stretch;
}

.DateRangePickerWrapper span {
    font-size: 1.3rem;
    line-height: 2.2rem;
}

.DateRangeButtonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: flex-end;
    margin-top: 3rem;
    flex-basis: 30%;
}

.DateRangeButtonContainer .DiscardButton, .DateRangeButtonContainer .ApplyButton {
    border-radius: .4rem;
    outline: none;
    padding: .4rem .8rem;
    width: calc(.15 * var(--width-large-screnn));
    height: 3.2rem;
    margin-bottom: 1rem;

}

@media only screen and (max-width: 100em) {
    .DateRangeButtonContainer .DiscardButton, .DateRangeButtonContainer .ApplyButton {
        width: calc(.15 * var(--width-small-screen));
        height: 3.2rem;

    }
}

.DiscardButton {
    border: .1rem solid var(--toyota-red-primary);
    background: var(--app-white);
    color: var(--toyota-red-primary);
    margin-right: 1rem;
}

.ApplyButton {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    border: .1rem solid transparent;
}

.ApplyButton:hover, .DiscardButton:hover {
    background-color: var(--hover-red-primary);
    color: var(--app-white);
}