.CreateCustomer {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}
.CreateCustomerModule {
  background-color: var(--app-white);
  min-height: calc(100vh - var(--content-header-height) + 1rem);
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  display: grid;
  grid-auto-columns: 20rem 1fr;
  grid-template-areas:
    "LeftNav RightContent"
}

.LeftNav{
    grid-column: 1;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.RightContent{
    grid-column: 2;
    padding-left: 5rem;
    text-align: left;
}

.NavItem {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: Toyota Type Regular;
    cursor: pointer;
    text-align: left;
    padding-left: 1.7rem;
}
.NavItemDisabled {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
  pointer-events: none;
  color: darkgrey;
}

.NavItemSelected{
    background-color: rgba(0, 160, 240, 0.1);
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: Toyota Type Regular;
    cursor: pointer;
    text-align: left;
    padding-left: 1.7rem;
}

.NodeItem{
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}

.EditCustomerModule1 {
  background-color: var(--app-white);
  min-height: calc(150rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  padding-left: 3rem;
}