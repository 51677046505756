.Container {
    height: 3.8rem;
    min-width: 20rem;
    display: flex;
}

.FileChooserContainer {
    width: 100%;
    height: 100%;
    border: .1rem solid var(--tme-grey-5-greys);
    border-radius: .4rem;
    margin-right: .5rem;
    display: flex;
    align-items: center;
    padding: .2rem 0;
}

.ChooseFileButton {
    margin-left: 1rem;
    outline: none;
    border-radius: .4rem;
    border: .1rem solid transparent;
    background: var(--tme-grey-6-greys);
    width: 9rem;
    height: 3rem;
    cursor: pointer;
    color: black;
}

.FileNameLabel {
    align-self: center;
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--tme-grey-1-greys);
    margin-left: 1rem;
}

.UploadButton {
    outline: none;
    border-radius: .4rem;
    border: .1rem solid transparent;
    background: var(--ct-blue-secondary);
    width: 10rem;
    color: white;
    cursor: pointer;
}

.UploadButton:hover {
    background: var(--ct-blue-hover-secondary);
}

.UploadButton:disabled {
    background: var(--tme-grey-4-greys);
    cursor: auto;
}