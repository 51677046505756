.MultiPicker {
  margin-top: 2rem;
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  align-items: stretch;
}

.MultiPickerTemplate {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  align-items: stretch;
}

.MultiPickerTemplate2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  align-items: stretch;
}