.Header {
    background-color: var(--app-white);
    height: var(--app-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.IconBox {
    margin-left: -1.75rem;
}

.ControlBox {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.SearchBox {
    width: 19rem;
    height: 3.6rem;
    margin-right: 2rem;
    position: relative;

}

.SearchBox input {
    font-size: 1.2rem;
    line-height: 1.38rem;
    font-weight: 400;
    /*background: var(--ct-background-grey);*/
    width: 100%;
    height: 100%;
    padding-left: 1.8rem;
    border: .1rem solid var(--tme-grey-6-greys);
    border-radius: 1.8rem;
    outline: none;
}

.SearchBox::after {
    content: url("../../assets/icons/common_search.svg");
    position: absolute;
    z-index: 1;
    right: .9rem;
    top: .9rem;
}

.NotificationBox {
    margin-right: 3rem;
}

.UserInfoBox {
    display: flex;
    margin-right: 1rem;
    flex-direction: column;
    align-items: flex-start;
}

.UserInfoName {
    font-size: 1.4rem;
    line-height: 1.65rem;
    color: var(--tme-grey-1-greys);
}

.UserInfoEmail {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--tme-grey-1-greys);
}

.ProfileBox {
    display: flex;
    /* margin-right: 1rem; */
    flex-direction: row;
    cursor: pointer;
}

.ProfileIcon {
    position: relative;
    color: #8B8C90;
    flex-direction: row;
    margin-right: .8rem;
    top: .25rem;
    bottom: .245rem;
}
.ProfileArrow {
    margin-top: 1.3rem;
}

.PopOver {
    display: flex;
    margin-right: 3rem;
    flex-direction: column;
    align-items: flex-start;
    /* background: lawngreen; */
}

.PopOverEdit {
    font-size: 1.2rem;
    line-height: 1.65rem;
    margin-top: 10rem;
    padding: 1.5rem 1rem;
    padding-bottom: 1rem;
    color: var(--tme-grey-1-greys);
    cursor: pointer;
}