
.ButtonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3rem;
    width: calc(2*16rem + 2rem);
}

.ButtonContainer .DiscardButton,.ApplyButton,.ApplyButtonDisable {
    border-radius: .4rem;
    outline: none;
    padding: .4rem .8rem;
    width: 16rem;
    height: 3.2rem;
    margin-bottom: 1rem;

}

.DiscardButton {
    border: .1rem solid var(--toyota-red-primary);
    background: var(--app-white);
    color: var(--toyota-red-primary);
    margin-right: 1rem;
}

.ApplyButton {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    border: .1rem solid transparent;
}

.ApplyButtonDisable {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    border: .1rem solid transparent;
    opacity: 0.2;
    pointer-events: none;
}

.ApplyButton:hover,.DiscardButton:hover {
    background-color: var(--hover-red-primary);
    color: var(--app-white);
}