.TemplateGroupTitle {
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 1.7rem;
  cursor: pointer;
}

.TemplateType {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font: Toyota Type Regular;
}

.ChevronUp {
    width: 1.6rem;
    height: 1.6rem;
    content: url("../../../../../assets/icons/chevron-up.svg");
}

.ChevronDown {
    width: 1.6rem;
    height: 1.6rem;
    content: url("../../../../../assets/icons/chevron-down.svg")
}

.ItemCount {
    font-size: 1.3rem;
    line-height: 2rem;
    font: Toyota Type Book;
}

.TemplateGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 3rem;
}

.TemplateDetails {
  min-width: 20rem;
  min-height: 24.5rem;
  max-width: 20rem;
  max-height: 24.5rem;
  margin-right: 3.2rem;
  margin-bottom: 3.2rem;
}

.TemplatePreview {
  min-height: 20rem;
  min-width: 20rem;
  max-width: 20rem;
  max-height: 20rem;
  padding-top: 2rem;
  content: "";
  background-color: var(--tme-grey-6-greys);
  border: 1px solid var(--tme-grey-4-greys);
  text-decoration: none;
  /* display: flex; */
}

.VersionNumber {
  font-family: Toyota Type Semibold;
  font-size: 1.2rem;
  line-height: 2rem;
  color: var(--ct-blue-secondary);
  text-align: left;
  text-decoration: none;
  display: flex;
}

.TemplateName {
  font-family: Toyota Type Book;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: left;
  /* display: flex; */
}
