.Btn {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    width: 18.8rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
}

.DiscardBtn {
    background: #F5F5F5;
    color: #000000;
    font-size: 1.2rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
}

.DiscardBtn:hover {
    background: var(--hover-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
}

.SaveBtn {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
}

.SaveBtn:hover {
    background: var(--hover-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
}

.SaveCloseBtn {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
}

.SaveCloseBtn:hover {
    background: var(--hover-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
}

.PublishBtn {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    outline: none;
    cursor: pointer;
    margin-left: -1rem;
    opacity: 0.2;
    pointer-events: none;
}

.TemplateSave {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: 4rem;
}

.SaveCloseCampaignBtn {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    margin-top: 9.9rem;
    margin-left: 1rem;
}

.TemplateSaveClose {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 12rem;
    margin-left: 27rem;
}

.TemplatePublish {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 12rem;
    opacity: 0.2;
    pointer-events: none;
}

.ContentBtn {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    width: 20.8rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
}

.backBtn {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    align-items: center;
    margin-top: 5rem;
    float: right;
    width: 14rem;
}

.Btn:hover {
    background: var(--hover-red-primary);
}

.BtnVinListBack {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    align-items: center;
    margin-top: 5rem;
    float: right;
    width: 14rem;
    margin-right: 4.5rem;
}

.BtnVinListBack:hover {
    background: var(--hover-red-primary);
}

.backBtn:hover {
    background: var(--hover-red-primary);
}

.ContentBack {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1.6rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    align-items: center;
    margin-top: 5rem;
    float: right;
    width: 14rem;
    margin-left: 10rem;
}

.OkBtn {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    border-color: rgba(1, 1, 1, 0);
    color: var(--app-white);
    font-size: 1.2rem;
    width: 14rem;
    height: 3.2rem;
    outline: none;
    cursor: pointer;
    margin-right: 4rem;
    margin-bottom: 2rem;
    float: right;
}

.OkBtn:hover {
    background: var(--hover-red-primary);
}

.Back_CustomerList {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    align-items: center;
    margin-top: 5rem;
    float: right;
    width: 14rem;
    margin-right: 4rem;
    margin-bottom: 2rem;
}

.DeleteCopyFile {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 12rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-left: 54rem;
    margin-top: 1rem;
}

.BtnCustomerEditSave {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: 10rem;
}

.BtnCustomerEditSave:hover {
    background: var(--hover-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
}

.DeleteVinListFile {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-left: 47.7rem;
}

.DeleteVinListFile:hover {
    background: var(--hover-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
}

.CompleteTemplateDiscard {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: -68rem;
}
.DiscardAddUser{
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-right:1rem;
}

.AddUser {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
 }

 /*Template*/
 .DiscardCreateTemplate {
    background: #F5F5F5;
    color: #000000;
    font-size: 1.2rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left:8rem;
}

/* Content Module */
.ContentSave {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: -5rem;
}
.creativeBriefContent{
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left:6rem;
}
.NextcreativeBriefContent{
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 4rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
}
.ContentEditSaveAndClose{
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left:3rem;
}
.ContentBriefEditPublish{
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left:1rem;
    opacity: 0.2;
    pointer-events: none;
}

/* Content Edit */
.ContentEditsaveclose{
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: 1rem;
}
.ContentEditAddCreativeBrief{
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-top: 10rem;
    margin-left: 1rem;
}

/* Copy */
.CopyEditCompleteSave {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    margin-left: -1rem;
}
.CopEditCompleteSave:hover {
    background: var(--hover-red-primary);
}

.CopyEditCompleteDiscard {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-left: 26rem;
}
.CopyEditCompleteDiscard:hover {
    background: var(--hover-red-primary);
    color: var(--app-white);
}

.CopyNewCompleteSave {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    margin-left: -4rem;
}
.CopyNewCompleteSave:hover {
    background: var(--hover-red-primary);
}

.CopyNewCompleteDiscard {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-left: 15rem;
}
.CopyNewCompleteDiscard:hover {
    background: var(--hover-red-primary);
    color: var(--app-white);
}

.CopyCloneSave {
    background: var(--toyota-red-primary);
    border: .1rem solid var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    margin-left: 37rem;
    margin-top: 7rem;
}
.CopyCloneDiscard {
    background: white;
    color: var(--toyota-red-primary);
    font-size: 1rem;
    width: 16rem;
    height: 3.2rem;
    border-radius: .4rem;
    border-color: rgba(1, 1, 1, 0);
    border: .1rem solid var(--toyota-red-primary);
    outline: none;
    cursor: pointer;
    margin-left: 70rem;
    margin-top: 7rem;
}

.BtnTemplateTypes {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    font-size: 1.6rem;
    width: 18.8rem;
    height: 4rem;
    padding: .2rem;
    border-radius: .3rem;
    border-color: rgba(1, 1, 1, 0);
    outline: none;
    cursor: pointer;
    margin-left: 30rem;
}