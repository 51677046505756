.ManageCampaignModule {
    background-color: var(--app-white);
    height: calc(100% - var(--content-header-height));
    border-radius: .5rem;
    box-shadow: 0 .8rem .8rem rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2rem;
}

.Priority {
    display: flex;
    align-items: flex-start;
}

.Priority label {
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 1.5rem;
}

.Priority label span {
    color: red;
}

.PriorityList {
    margin-top: auto;
    text-align: "left";
    margin-left: auto;
}

.UpdateFormSubTitle {
    font-family: "Toyota Type Regular";
    font-style: normal;
    font-size: 1.2rem;
    color: #282830;
}

.Buttons {
    align-self: flex-end;
    margin-right: 22rem;
    margin-bottom: 2rem;
}