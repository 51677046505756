.Container {
  height: 100%;
  width: 100%;
}

.CustomerList {
  padding: 1rem 2rem 3rem;
}

.AssociatedCampaigns {
  max-width: 20rem;
  height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
