.SelectorBoxContainer {
    position: relative;
    display: inline-block;
    background-color: var(--app-white);
    border-radius: .4rem;
    text-align: left;
    border: .1rem solid var(--tme-grey-5-greys);
}

.SelectBox {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding: .7rem 1rem;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.SelectBox:focus {
    outline: 0;
    box-shadow: 0 0 .4rem #0493d1;
}

.SelectBox::selection {
    background: none;
}

.SelectBox::before {
    content: ' ';
    z-index: 1;
    position: absolute;
    height: 2.4rem;
    top: .7rem;
    right: 3.4rem;
    border-left: .1rem solid var(--tme-grey-5-greys);
}

.SelectBox::after {
    content: url("../../../assets/icons/chevron-down.svg");
    position: absolute;
    z-index: 1;
    top: .8rem;
    right: .5rem;
}

.SelectBoxOptionsAndButtonsContainer {
    display: flex;
    flex-direction: column;
    max-height: 20rem;
}

.NoOptionsMessage {
    text-align: center;
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding: .5rem;

}

.SelectBoxOptions {
    width: 100%;
    margin: .2rem 0 0;
    position: absolute;
    padding: .1rem 0;
    top: 100%;
    left: 0;
    z-index: 4;
    background-color: var(--app-white);
    border: .1rem solid transparent;
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: 0 0 .4rem .4rem;
}

.SelectBoxHidden {
    display: none;
}

.Options {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.Option {
    padding: .6rem 3rem;
    margin: 0;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 2rem;;
    position: relative;
}

.Option:hover {
    background: var(--ct-blue-secondary-op-light);
}

.Option::selection {
    background: none;
}

.Option::before {
    content: ' ';
    position: absolute;
    line-height: 1.2;
    text-align: center;
    left: .8rem;
    top: .9rem;
    height: 1.4rem;
    width: 1.4rem;
    margin-right: .8rem;
    border: .1rem solid var(--tme-grey-5-greys);
    background: var(--app-white);
    vertical-align: middle;
}

.OptionSelected::before {
    background: var(--ct-blue-secondary);
}

.Buttons {
    align-self: flex-end;
    padding: .8rem 1rem;
}

.Button {
    background: transparent;
    border: none;
    color: var(--ct-blue-secondary);
    font-weight: 500;
    outline: none;
    cursor: pointer;
}

.Button::selection {
    background: none;
}

.Button:hover {
    color: var(--ct-blue-hover-secondary);
}

.Clear {
    margin-right: 1rem;
    font-weight: 400;
}

.Clear:disabled {
    color: var(--tme-grey-3-greys);
    cursor: auto;
}