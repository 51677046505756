:root {
    --app-white: #fff;
    --content-background: #e5e5e5;

    --toyota-red-primary: #ff0022;
    --hover-red-primary: #D90022;
    --toyota-grey-primary: #585958;
    --grey-primary: #282830;
    --black-primary: #000;

    --tme-grey-1-greys: #494950;
    --tme-grey-2-greys: #6A6B70;
    --tme-grey-3-greys: #8B8C90;
    --tme-grey-4-greys: #ADADB0;
    --tme-grey-5-greys: #CECFD0;
    --tme-grey-6-greys: #F5F5F5;

    --ct-blue-secondary: #5592ff;
    --ct-blue-hover-secondary: #2672ff;
    --ct-blue-secondary-op-med-light: rgba(85, 146, 255, 0.5);
    --ct-blue-secondary-op-light: rgba(85, 146, 225, 0.1);
    --ct-green-secondary: #0A9F98;
    --ct-orange-secondary: #F77B00;
    --ct-red-secondary: #FF5958;
    --ct-yellow-secondary: #FFBD00;

    --ct-blue-light-secondary: #CCDEFF;
    --ct-green-light-secondary: #E1FBF3;
    --ct-orange-light-secondary: #FDD7B3;
    --ct-red-light-secondary: #FFCDCD;
    --ct-yellow-light-secondary: #FFEBB3;

    --ct-background-grey: #F5F6FA;

    --ct-backgound-highlight: #00A0F0;

    --app-width: 144rem;

    --app-header-height: 7rem;
    --nav-height: 4.4rem;

/*    modal props  */
    --width-large-screnn: 40vw;
    --width-small-screen: 65vw;

/*    content header height */
    --content-header-height: 7rem;
}


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    /* 1rem = 10px */
    font-size: 62.5%;
}

body {
    box-sizing: border-box;

    font-family: "Toyota Type Regular", -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body h3 {
    line-height: 3.2rem;
    color: var(--grey-primary);
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
}

code {
    font-family: "Toyota Type Regular", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
    width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--tme-grey-5-greys);
    border-radius: .2rem;
}

@font-face {
    font-family: "Toyota Type Black";
    src: url("./assets/fonts/toyota-type/ToyotaType-Black.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Black It";
    src: url("./assets/fonts/toyota-type/ToyotaType-BlackIt.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Bold";
    src: url("./assets/fonts/toyota-type/ToyotaType-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Bold It";
    src: url("./assets/fonts/toyota-type/ToyotaType-BoldIt.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Book";
    src: url("./assets/fonts/toyota-type/ToyotaType-Book.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Book It";
    src: url("./assets/fonts/toyota-type/ToyotaType-BookIt.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Light";
    src: url("./assets/fonts/toyota-type/ToyotaType-Light.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Light It";
    src: url("./assets/fonts/toyota-type/ToyotaType-LightIt.otf") format("opentype");
}

@font-face {
    font-family: "Toyota Type Regular";
    src: url("./assets/fonts/toyota-type/ToyotaType-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Toyota Type Regular It";
    src: url("./assets/fonts/toyota-type/ToyotaType-RegularIt.otf") format("opentype");
}
@font-face {
    font-family: "Toyota Type Semibold";
    src: url("./assets/fonts/toyota-type/ToyotaType-Semibold.otf") format("opentype");
}
@font-face {
    font-family: "Toyota Type Semibold It";
    src: url("./assets/fonts/toyota-type/ToyotaType-SemiboldIt.otf") format("opentype");
}