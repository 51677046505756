.TemplateCard {
    min-width: 20rem;
    min-height: 24.5rem;
    max-width: 20rem;
    max-height: 24.5rem;
    margin-right: 3.2rem;
    margin-bottom: 3.2rem;
  }
  
  .TemplatePreview {
    min-height: 20rem;
    min-width: 20rem;
    max-width: 20rem;
    max-height: 20rem;
    padding-top: 2rem;
    content: "";
    background-color: var(--tme-grey-6-greys);
    border: 1px solid var(--tme-grey-4-greys);
    text-decoration: none;
    /* display: flex; */
  }
  
  .TemplateNumber {
    font-family: Toyota Type Semibold;
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--ct-blue-secondary);
    text-align: left;
    text-decoration: none;
    display: flex;
  }
  
  .TemplateName {
    font-family: Toyota Type Book;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: left;
    /* display: flex; */
  }