.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ContentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Header {
    padding: 0 2rem 0 4rem;
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--tme-grey-6-greys);
    margin-bottom: 2rem;
}

.CloseIcon {
    cursor: pointer;
    fill: var(--tme-grey-2-greys);
}

.CloseIcon:hover {
    fill: var(--tme-grey-4-greys);
}

.Header > p {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-top: .6rem;
}

.Header > p > span {
    margin-right: 1.5rem;
}

.Body {
    padding-left: 4rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.Body > p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
}

.Body > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.Body > ul > li {
    font-size: 1.2rem;
    line-height: 2rem;
}

.NoteContainer {
    width: 100%;
    padding: 0 3rem 0 3rem;
}

.NoteContainer label {
    font-size: 1.6rem;
    line-height: 2.4;
}

.Buttons {
    align-self: flex-end;
    margin-right: 2rem;
    margin-bottom: 2rem;
}