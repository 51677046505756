.CurrentPathDisplayContainer {
    display: flex;
}

.CurrentPathDisplayContainer span {
    font-family: "Toyota Type Semibold", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-right: .5rem;
    text-transform: uppercase;
}

.CurrentPathDisplayContainer span:nth-last-child(2) {
    color: var(--toyota-red-primary);
}

.CurrentPathDisplayContainer span:nth-last-child(1) {
    display: none;
}