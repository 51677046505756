.CategoryCellContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 6rem;
    padding: 1rem 0;
}

.CategoryLabel {
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.SubCategoryLabel {
    font-size: 1.2rem;
    line-height: 2.4rem;
}