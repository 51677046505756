.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 12rem;
  padding: 1rem 0;
}

.CustomerListHeader {
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: var(--ct-blue-secondary);
  height: 1.64rem;
  width: 15rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CustomerListDescription {
  font-size: 1.2rem;
  line-height: 1.8rem;
  height: 3.6rem;
  width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CreatedDate {
  font-size: 1.2rem;
  line-height: 1.6rem;
}