.HorizonalSep {
  width: calc(100% - 8rem);
  margin-left: 4rem;
  margin-top: 3.2rem;
  border-top: 0.1rem solid var(--tme-grey-5-greys);
  margin-bottom: 3rem;
}

.Container {
  padding: 4rem;
  padding-left: 0;
}

.TableNav {
  display: flex;
  margin-left: 4rem;
  border-bottom: .1rem solid var(--tme-grey-5-greys);
}

.NavItem {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  padding-bottom:1.4rem;
  cursor: pointer;
}

.NavItemSelected {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  border-bottom: 2px solid var(--toyota-red-primary);
  color: var(--toyota-red-primary);
  padding-bottom:1.4rem;
  cursor: pointer;
}

.TableCol {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  /* overflow: scroll; */
  min-height: 36rem;
  padding-top: 2.4rem;
}

.TableRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.VerticalSep {
  align-self: stretch;
  background-color: var(--tme-grey-5-greys);
  width: 0.1rem;
}

/* for version history*/
.Circle {
  order: 0;
  flex-grow: 0;
  flex-basis: 20%;
}

.ColTitle {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.PrimaryVersion {
  text-align: left;
  padding-left: 4rem;
}

.PrimaryLabel {
  color: var(--ct-blue-secondary);
  font: Toyota Type;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.VersionDateTime {
  font: Toyota Type;
  line-height: 2rem;
  font-size: 1.2rem;
  color: var(--black-primary);
}

.VersionItem {
  display: flex;
  flex-direction: row;
  padding-left: 4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.VersionItemSelected {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 160, 240, 0.1);
  padding-left: 4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.TrailCol {
  display: flex;
}

.TemplateInfoCol {
  display: flex;
  flex-direction: column;
  padding-left: 2.6rem;
  text-align: left;
}

.TrailDot {
  height: 1.6rem;
  width: 1.6rem;
  content: "";
  border: 1px solid var(--ct-blue-secondary);
  border-radius: 50%;
  background-color: var(--app-white);
  margin-top: 0.3rem;
  z-index: 1;
}

.TrailLine {
  position: relative;
  width: 64px;
  height: 1px;
  left: -177.5px;
  top: 36px;
  border: 1px solid #5592ff;
  background-color: #5592ff;
  transform: rotate(90deg);
}

.TemplateContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 3rem;
}
