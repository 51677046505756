.CustomerSegmentTable {
  height: 35rem;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 8rem;
  padding-top: 3.2rem;
}

.TableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2.4rem;
}

.Col1Title {
  width: 20rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font: Toyota Type;
}

.Col2Title {
  width: 8rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font: Toyota Type;
}

.Col3Title {
  width: 12rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font: Toyota Type;
}

.Col4Title {
  width: 4rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font: Toyota Type;
}

.Col5Title {
  width: 18rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
  font: Toyota Type;
}

.Col1Data {
  width: 20rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font: Toyota Type;
  color: var(--ct-blue-secondary);
}

.Col2Data {
  width: 8rem;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
}

.Col3Data {
  width: 12rem;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
}

.Col4Data {
  width: 4rem;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
}

.Col5Data {
  width: 18rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
}
