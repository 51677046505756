.Container {
    height: 100%;
    width: 100%;
}

.CustomerList {
    padding: 1rem 2rem 3rem;
}

.CreateCustomer {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
}

.Header {
    font-family: Toyota Type Regular;
    font-size: 20px;
    line-height: 28px;
    color: #282830;
    font-weight: bolder;
    margin-top: 1.1rem;;
}

.Content {
    font-family: Toyota Type Regular;
    font-size: 16px;
    line-height: 24px;
    color: #282830;
}

.TemplateContainer {
    display: flex;
    height: 100%;
}

.Select {
    width: 400px;
    margin-top: 25px;
}

.SelectRuleForm {
    width: 260px;
    height: 40px;
    font-size: 14px;
}

.NavigationMenu {
    /* position: absolute; */
    width: 200px;
    /* height: 1651px; */
    left: 137px;
    top: 232px;
    /* Primary White */
    background: #ffffff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    /* margin-left: -4rem; */
    /* width: 21rem; */
    padding-left: 1.7rem;
    margin: -2rem auto auto -4rem;
}

Form.Label{
    margin-top: 10px;
}


/* .NavigationMenu label {
  text-align: left;
  font-size: 1.6rem;
} */

.CreateCustomer label {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 1.5rem;
}

.CreateCustomer label span {
    color: red;
}

.MenuList {
    position: static;
    font-family: Toyota Type Regular;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #282830;
    margin: 2rem 0rem;
    cursor: pointer;
    text-align: left;
    padding: 0.5rem;
}

.FormView {
    display: flex;
    flex-direction: row;
}

.Form {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 1.47%;
    right: 10.81%;
    top: 26.2%;
    bottom: 23.8%;
}

.CustomerCount {
    background-color: var(--app-white);
    border-radius: 0.5rem;
    box-shadow: 0 0.8rem 0.8rem rgb(0 0 0 / 15%);
}

.Circle {
    padding: 25px;
}

.CircleText {
    padding: 72px;
}

.CustomerNavTab {
    padding-top: 6.8rem;
    justify-content: space-between;
    font-size: small
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: red;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #0A9F98 !important;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.VehicleDetails {
    height: 800px;
}

.AddRuleButton {
    height: 32px;
    width: 160px;
    left: 885px;
    top: 220px;
    border-radius: 4px;
    padding: 4px, 0px, 4px, 0px;
    position: absolute;
}

.PopoverTitle {
    font-size: large;
}

.Block {
    height: 50px;
    margin-top: 18px;
    background: #F5F6FA;
}

.Block p {
    font-family: Toyota Type Regular;
    font-size: 16px;
    line-height: 25px;
    color: #282830;
    padding: 12px;
    font-weight: bold;
}

.Dashed {
    width: 111px;
    height: 0;
    border: 1px solid #C4C4C4;
    margin: 3px;
    display: inline-block;
}

.Discard {
    left: 55px;
    /* display: inline-table; */
    position: absolute;
    margin-top: 790px;
    width: 75px;
}

.Save {
    left: 900px;
    /* display: inline-table; */
    position: absolute;
    margin-top: 790px;
    width: 75px;
}

.Publish {
    left: 1000px;
    /* display: inline-table; */
    position: absolute;
    margin-top: 790px;
    width: 75px;
}

.Title {
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}