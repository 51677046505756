.Container {
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 2rem;
}

.Button {
    border: .2rem solid var(--tme-grey-5-greys);
    width: 1.6rem;
    height: 1.6rem;
    border-radius: .8rem;
    margin-right: .5rem;
}

.Button.Selected {
    background: var(--ct-blue-secondary);
}

.Container span {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0;
    cursor: pointer;
}

.Container span::selection {
    background: none;
}