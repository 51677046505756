.ItemHistory {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.TrailItemSpace {
  order: 0;
  flex-grow: 1;
  flex-basis: 0;
}

.CommentContent {
  order: 1;
  flex-grow: 1;
  flex-basis: 60%;
  text-align: left;
}

.Textbox {
    min-width: 100%;
    min-height: 9.6rem;
    border: 1px solid var(--tme-grey-5-greys);
    resize: none;
}

.Textbox:focus {
  outline:none;
}

.DoneBtn {
    color: var(--ct-blue-secondary);
    border: none;
    background: none;
}
.CancelBtn {
  color: var(--ct-blue-secondary);
  padding-left: 1rem;
  border: none;
  background: none;
}

.TrailLine {
  position: relative;
  width: 128px;
  height: 1px;
  left: 2px;
  top: 85px;
  border-top: 1px solid #5592ff;
  background-color: #5592ff;
  transform: rotate(90deg);
}
