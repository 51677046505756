.MngCmpgnTblHd {
    display: flex;
    flex-direction: row;
    height: 8rem;
    width: 100%;
}

.MngCmpgnTblHdSelector {
    width: 100%;
}

.SortByContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.SortBy {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--grey-primary);
    margin-bottom: 1.25rem;
}

.SortBySelect {
    width: 25rem;
    min-width: 20rem;
    text-align: left;
}

.TableHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    width: 100%;
    padding: 0 2rem;
}

.TableHeadLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 10rem;
    width: 40%;
    padding-bottom: 1rem;
}

.TableHeadRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 10rem;
    margin-right: 2rem;
}

.TableHeadRight {
    width: 33.3%;
}

.Calendar {
    margin-left: 1rem;
    outline: none;
    height: 4rem;
    width: 10.2rem;
    border-radius: 0.4rem;
    border: 1px solid var(--tme-grey-5-greys);
    background: var(--app-white);
    color: var(--grey-primary);
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.Menu {
    color: var(--grey-primary);
    cursor: pointer;
    width: 40px;
    height: 2.8rem;
}

.Calendar1 {
    color: var(--grey-primary);
    cursor: pointer;
    width: 40px;
    height: 2.8rem;
}