.Container {
    margin-top: 2rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
}
.ContainerDisable {
    margin-top: 2rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    color: darkgrey;
}

.TemplateArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.SubCategoryArea{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Card {
    width: calc(1 / 2 * var(--app-width) - 6.5rem);
    height: calc(1 / 4 * (var(--app-width) - 14rem));

    border: 1px solid var(--tme-grey-5-greys);
    border-radius: .4rem;

    font-size: 1.6rem;
    line-height: 2.4rem;

    color: var(--ct-blue-secondary);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.Card1 {
    width: calc(1 / 2 * var(--app-width) - 6.5rem);
    height: calc(1 / 4 * (var(--app-width) - 14rem));

    border: 1px solid var(--tme-grey-5-greys);
    border-radius: .4rem;

    font-size: 1.6rem;
    line-height: 2.4rem;
    flex-flow: row wrap;
    color: var(--ct-blue-secondary);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    flex-wrap: wrap;
    align-content: flex-start;
}
.selectedTemplatesList{
    background-color:"#F5F5F5";
    align-items:"left";
    margin-bottom:"20rem";
    font-family:"Toyota Type Regular";
    font-style:"normal";
    color:"#282830";
}