.tableFixHead {
    padding: 0 2rem;
    margin-top: 2rem;
    overflow-y: auto;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}

.tableFixHead thead th {
    background: #F6F7FB;
    height: 4.4rem;
    font-size: 1.2rem;
    line-height: 1.38rem;
    text-align: left;
}

.tableFixHead thead th:first-child {
    text-align: left;
    padding-left: 1rem;
}

.Table {
    width: 100%;
    border-collapse: collapse;
    overflow: scroll;

}

.tableFixHead tbody tr {
    border-bottom: 1px solid #E4E7F2;
    text-align: left;
}


.tableFixHead td {
    height: 4.5rem;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.38rem;
    padding: .2rem;

}