.HorizonalSep {
  width: calc(100% - 8rem);
  margin-left: 4rem;
  margin-top: 3.2rem;
  border-top: 0.1rem solid var(--tme-grey-5-greys);
  margin-bottom: 3rem;
}

.TableCol {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow: scroll;
  max-height: 36rem;
}

.TableRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.VerticalSep {
  align-self: stretch;
  background-color: var(--tme-grey-5-greys);
  margin: 0 1rem;
  width: 0.1rem;
  transform: translateY(-3rem);
}

/* for version history*/
.ItemHistory {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

.Circle {
  order: 0;
  flex-grow: 0;
  flex-basis: 20%;
}

.VersionDateTime {
  order: 1;
  flex-grow: 1;
  flex-basis: 60%;
  text-align: left;
}

.Status {
  order: 2;
  flex-grow: 1;
  flex-basis: 20%;
}

.ColTitle {
  text-align: left;
  padding-left: 46px;
  font-size: 16px;
  line-height: 24px;
}

/*flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0rem 3.2rem;
    text-align: left;
    font-family: 'Toyota Type Regular';*/