.Pages {
    display: flex;
    /*justify-content: space-evenly;*/
    align-items: center;
    width: 20%;
}

.PageNav {
    width: 2.2rem;
    height: 2.2rem;
    background: #ebeaea;
    border-radius: .2rem;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PageNav.Selected {
    background: var(--hover-red-primary);
    color: var(--app-white);
}

.PageNav:hover {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    cursor: pointer;
}

.PageNav:not(:last-child) {
    margin-right: 1rem;
}

.Arrow {
    border: solid black;
    border-width: 0 .1rem .1rem 0;
    display: inline-block;
    padding: .25rem;
}

.Left {
    transform: rotate(135deg);
}

.FarLeft {
    margin-left: .25rem;
}

.Right {
    transform: rotate(-45deg);
}

.FarRight {
    margin-right: .25rem;
}

.PageNav:hover .Left, .PageNav:hover .Right {
    border: solid white;
    border-width: 0 .1rem .1rem 0;
}

