.Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index:1;
}

.Header {
    padding: 0 2rem 0 3rem;
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--tme-grey-6-greys);
    margin-bottom: 4rem;
}

.CloseIcon {
    cursor: pointer;
    fill: var(--tme-grey-2-greys);
}

.CloseIcon:hover {
    fill: var(--tme-grey-4-greys);
}

.Header>p {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.Body {
    display: flex;
    align-items: center;
    align-self: center;
}

.Body>p {
    margin-left: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 0rem;
}

.Buttons {
    align-self: flex-end;
    margin-right: 2rem;
    margin-bottom: 2rem;
}

.Body2 {
    font-size: 1.3rem;
    margin-left: 2rem;
}

.bold {
    font-weight: bold;
}
.launchDate{
    align-items: center;
    align-self: center;
    margin-left: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 0rem;
}