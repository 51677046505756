.ViewReport {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}
.ViewReport label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.ViewReport label span {
  color: red;
}

.AddReportModule {
  background-color: var(--app-white);
  min-height: calc(165rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  /* padding: 3.1rem 0; */
  display: grid;
  grid-auto-columns: 20rem 1fr;
  grid-template-areas: "LeftNav RightContent";
}

.LeftContent {
  grid-column: 1;
  /* border: 1px solid black; */
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.RightContent {
  grid-column: 2;
  padding-top: 13rem;
  /* border: 1px solid black; */
  padding-left: 5rem;
  /* padding-right: 4rem; */
  text-align: left;
  margin-right: 4rem;
}

.RightContentReport {
  grid-column: 2;
  padding-top: 6rem;
  /* border: 1px solid black; */
  padding-left: 5rem;
  /* padding-right: 4rem; */
  text-align: left;
  margin-right: 4rem;
}

.NavItemSelected {
  background-color: rgba(77, 187, 241, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavItem {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "Toyota Type Regular";
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}
.UpdateFormHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}
.UpdateFormSubTitle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #282830;
}
.SuccessPageContent{
font-family:"Toyota Type Regular";
font-style: normal;
font-weight: normal;
font-size: 1.6rem;
line-height: 2.4rem;
}
.FloatContainer{
  /* border: 3px solid blue; */
  height:20%;
  padding-left: 2rem;
  padding-top: 10rem;
}
.FloatContainerFlex{
 
  display: flex;
  height: 300px;
  width: 100%;
}

.child {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family:"Toyota Type Regular";
font-style: normal;
font-weight: normal;
  width: 100%;  /* Or whatever */
  height: 100px; /* Or whatever */
  margin: auto;  /* Magic! */
}
.floatChild {
  width: 45%;
  float: left;
  /* padding: 30px; */
  /* border: 2px solid red; */
  height:"400px";
  width:"400px";
}
.floatChild1 {
  width: 45%;
  float: left;
  padding-left: 4rem;
  /* padding-top:3rem; */
  /* border: 2px solid red; */
  height:"400px";
  width:"400px";
}

.Chart {
  font-size: 1.6rem;
}

.ChartCard {
  height: 21.4rem;
  width: calc(100% - 5rem);
  border-radius: 0.9rem;
  margin-right: 2.4rem;
  /* box-shadow: 0px 8px 8px rgba(0.15, 0.15, 0.15, 0.15); */
  display: flex;
}

.PieChart {
  height: 16.6rem;
  width: 16.6rem;
  padding-top: 2.4rem;
  margin-left: 1.8rem;
}

.ChartKey {
  padding-left: 6rem;
  display: flex;
  flex-direction: column;
  padding-top: calc(21.4rem - 14rem);
}

.ChartKeyDot {
  height: 0.6rem;
  width: 0.6rem;
  content: "•";
  border-radius: 50%;
  color: var(--ct-green-secondary);
  border: 1px solid var(--ct-green-secondary);
  background-color: var(--ct-green-secondary);
  margin-top: 1rem;
  margin-right: 1rem;
}

.ChartKeyLabel {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.ChartLabelWithDot {
  display: flex;
}

.ChartKeyNumber {
  font-size: 2rem;
  line-height: 2.8rem;
  margin-left: 1.5rem;
}

.BrandAndCampaign {
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* border:2px solid red; */
  }

.ReportMessage {
  padding: 0 1rem;
   display: flex;
  /* align-items: flex-end; */
  justify-content: space-between;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  /* border:2px solid yellow; */
  height:10%;
}
.Select {
  width: 50%;
}
/* .reportContainer {
  border: 3px solid #fff;
  padding: 20px;
  
}

.reportchild {
  width: 50%;
  float: left;
  padding: 20px;
  border: 2px solid red;
} */

.reportContainer {
  display: flex;
}

.reportchild {
  flex: 1;
  /* border: 2px solid yellow; */
  font-size: 1.7rem;
  flex-basis: 58%;
  width:65%;
  padding-top: 2rem;
}
.reportchild:first-child {
  /* border: 2px solid green; */
  margin-right: 20px;
  font-size: 2.0rem;
  flex-basis: 0%;
}
.cardBody {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.0rem;
  background-color: #e8aeae;
  border-radius: 20px;
}