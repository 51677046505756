.VersionItem {
  display: flex;
  flex-direction: row;
  padding-left: 4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.VersionItemSelected {
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 160, 240, 0.1);
    padding-left: 4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .PrimaryLabel {
    color: var(--ct-blue-secondary);
    font: Toyota Type;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  
  .VersionDateTime {
    font: Toyota Type;
    line-height: 2rem;
    font-size: 1.2rem;
    color: var(--black-primary);
  }
  
  .TrailCol {
    display: flex;
  }
  
  .TemplateInfoCol {
    display: flex;
    flex-direction: column;
    padding-left: 2.6rem;
    text-align: left;
  }
  
  .TrailDot {
    height: 1.6rem;
    width: 1.6rem;
    content: "";
    border: 1px solid var(--ct-blue-secondary);
    border-radius: 50%;
    background-color: var(--app-white);
    margin-top: 0.3rem;
    z-index: 1;
  }
  
  .TrailLine {
    position: relative;
    width: 64px;
    height: 1px;
    left: -188.5px;
    top: 36px;
    border-top: 1px solid #5592ff;
    background-color: #5592ff;
    transform: rotate(90deg);
  }