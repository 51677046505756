.CreateUser {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}
.CreateUser label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.CreateUser label span {
  color: red;
}

.BrandAndCampaign {
  padding: 2rem 0 2rem 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.BrandCombo {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.Brand {
  min-width: 20rem;
  height: 3.8rem;
}

.CampaignNameCombo {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.CampaignNameBox {
  height: 3.8rem;
  border: 0.1rem solid var(--tme-grey-5-greys);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-left: 1rem;
}

.CampaignNameBox:focus {
  outline: none;
}

.ChannelContainer {
  margin-top: 3.5rem;
  text-align: left;
  padding: 0 1rem;
}

.CategorySubCategoryCombo {
  margin-top: 2rem;
  display: flex;
  padding: 0 1rem;
  align-items: flex-end;
  justify-content: space-between;
}
.Select {
  width: 98%;
}

.Input {
  width: 49%;
  margin-top: 5rem;
}
.teamsParent{
  align-items: left;
  flex-direction: column;
  width:50%;
}
.teamsTitle{
  width: 49%;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
 
}
.teamsParent label span {
  color: red;
}

.CreateCustomerBlock {
  display: flex;
}

.TemplateContainer {
  display: flex;
  flex-direction: column;
}

.Approval {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "CreativeApproval ApprovalProof"
    "DivisionApproval DivisionApprovalUploads"
    "LegalApproval LegalApprovalProof";
  align-items: end;
  padding: 1rem 2rem;
}

.Approval label {
  margin-bottom: 0;
}

.CreativeApproval {
  grid-area: CreativeApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.DivisionApproval {
  grid-area: DivisionApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.LegalApproval {
  grid-area: LegalApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.ApprovalProof {
  grid-area: ApprovalProof;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.DivisionApprovalUploads {
  grid-area: DivisionApprovalUploads;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.LegalApprovalProof {
  grid-area: LegalApprovalProof;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.CalendarSelector {
  margin-top: 1rem;
  width: 100%;
  height: 3.8rem;
}

.CustomerListAndDeliveryRulesContainer {
  margin-top: 3.5rem;
  text-align: left;
  padding: 0 1rem;
}

.CustomerListAndDeliveryRulesContainer > div {
  margin-bottom: 2rem;
}

.Buttons {
  align-self: flex-end;
  padding-right: 1rem;
}

.ViewNotice {
  padding-bottom: 2rem;
  padding-top:2rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: white;
  background-color: var(--toyota-red-primary);
}

.AddUserTitle{
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: #282830;
}
.AddUserSubTitle{
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: "Toyota Type Book";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #282830;
}

.AddUser {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}

.AddUserModule {
  background-color: var(--app-white);
  min-height: calc(165rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  /* padding: 3.1rem 0; */
  display: grid;
  grid-auto-columns: 20rem 1fr;
  grid-template-areas: "LeftNav RightContent";
}
.SaveAndDiscardButton{
  align-self: flex-end;
  padding-right: 0rem;
  margin-top:10rem;
  justify-content: flex-end;
  display: flex;
  width:100%;
}

.Status {
 width:49%;
}

.TeamAndRole {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width:49%;
 
}
.TeamAndRoleTitle{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
 
}

.TeamAndRoleTitle1{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.LeftContent {
  grid-column: 1;
  /* border: 1px solid black; */
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.RightContent {
  grid-column: 2;
  padding-top: 2rem;
  /* border: 1px solid black; */
  padding-left: 5rem;
  /* padding-right: 4rem; */
  text-align: left;
  margin-right: 4rem;
}
.CreateUserContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-auto-rows: 45.9rem 1fr; */
  grid-template-areas:
    "UpperContent"
    "LowerContent";
}

.NavItemSelected {
  background-color: rgba(77, 187, 241, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavItem {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "Toyota Type Regular";
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}
.UpdateFormHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}
.UpdateFormSubTitle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #282830;
}
.SuccessPageContent{
font-family:"Toyota Type Regular";
font-style: normal;
font-weight: normal;
font-size: 1.6rem;
line-height: 2.4rem;
}
.RoleTabs {
  display: flex;
  margin-left: -5rem;
  padding-left: 5rem;
  border-bottom: 0.1rem solid var(--tme-grey-5-greys);
  /* justify-content: space-between; */
  margin-top: 5rem;
  margin-right: -4rem;
}

.RoleItem {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  padding-bottom: 1.4rem;
  cursor: pointer;
  margin-right: 9rem;
}

.RoleItemSelected {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  border-bottom: 2px solid var(--toyota-red-primary);
  color: var(--toyota-red-primary);
  padding-bottom: 1.4rem;
  cursor: pointer;
  margin-right: 9rem;
}

.RoleContentDisplay {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-auto-columns: 90rem 1fr; */
  /* grid-auto-rows: 45.9rem 1fr; */
  grid-template-areas: "NodeContainer RuleContainer";
  min-height:50rem;
  margin-left:-4rem;
}

.EditRoleContentDisplay {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-auto-columns: 90rem 1fr; */
  /* grid-auto-rows: 45.9rem 1fr; */
  grid-template-areas: "NodeContainer RuleContainer";
  min-height:70rem;
  margin-left:-4rem;
}

.RoleContainer {
  display: grid;
  /* min-height: 70rem; */
}

.RoleAndTeam {
  padding: 0rem 0 2rem 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 2rem;
}
.RadioClass{
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex-direction: column;
}
.disable{
  pointer-events: none;
  color: darkgrey;
}
.floatcontainer{
  border: 3px solid #fff;
  margin-top: 3rem;
}
.floatchild{
  width: 50%;
  float: left;
  padding: 0px;
  margin-bottom: 0rem;
  padding-right: 1rem;
}