.SubHeading {
  padding-top: 2.9rem;
  line-height: 2.8;
  font-size: 2rem;
}

.HeadingDescription {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Book;
}

.InputContainer {
  padding-top: 4rem;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6rem;
  grid-template-areas: "InputFields Chart"; */
}

.disableContainer {
  padding-top: 4rem;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6rem;
  grid-template-areas: "InputFields Chart"; */
  opacity: 0.7;
  pointer-events: none;
}

.InputFields {
  padding-bottom: 3rem;
  width: 97%;
}

.InputFields label {
  font-size: 1.6rem;
}

.InputFields label span {
  color: red;
}

.Chart {
  font-size: 1.6rem;
  visibility: hidden;
}

.ChartCard {
  height: 21.4rem;
  width: calc(100% - 5rem);
  border-radius: 0.9rem;
  margin-right: 2.4rem;
  box-shadow: 0px 8px 8px rgba(0.15, 0.15, 0.15, 0.15);
  display: flex;
  visibility: hidden;
}

.PieChart {
  height: 16.6rem;
  width: 16.6rem;
  padding-top: 2.4rem;
  margin-left: 1.8rem;
}

.ChartKey {
  padding-left: 6rem;
  display: flex;
  flex-direction: column;
  padding-top: calc(21.4rem - 14rem);
}

.ChartKeyDot {
  height: 0.6rem;
  width: 0.6rem;
  content: "•";
  border-radius: 50%;
  color: var(--ct-green-secondary);
  border: 1px solid var(--ct-green-secondary);
  background-color: var(--ct-green-secondary);
  margin-top: 1rem;
  margin-right: 1rem;
}

.ChartKeyLabel {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.ChartLabelWithDot {
  display: flex;
}

.ChartKeyNumber {
  font-size: 2rem;
  line-height: 2.8rem;
  margin-left: 1.5rem;
}

.CreateCustomerCombo {
  padding-bottom: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.CreateCustomerCombo>label {
  font-size: 1.6;
}

.disableFields {
  opacity: 0.7;
  cursor: pointer;
}

.CreateCustomerComboDisabled {
  padding-bottom: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  opacity: 0.7;
  pointer-events: none;
}

.Select {
  padding-bottom: 1.6rem;
  font-size: 1.6rem;
}

.TableRow {
  height: 5.3rem;
  border-top: 1px solid var(--tme-grey-5-greys);
  border-bottom: 1px solid var(--tme-grey-5-greys);
}

.VinListTablePopulated {
  height: 52.4rem;
  /* background-color: var(--ct-background-grey); */
  /* border: 1px solid var(--tme-grey-5-greys); */
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 52rem;
}

.TableScroll {
  overflow: scroll;
  height: 50rem;
}

.VinListUpload {
  width: 100%;
  height: 100%;
  border: .1rem solid var(--tme-grey-5-greys);
  border-radius: .4rem;
  margin-right: .5rem;
  display: flex;
  align-items: center;
  padding: .2rem 0;
  margin-bottom: 5rem;
}

.VinListUploadLabel {
  font-size: 1.6rem;
  justify-content: left !important;
  margin-top: -7rem;
}

.VinListUploadLabel span {
  color: red;
}

.VinListTitle {
  width: 55rem;
  align-items: flex-start;
}

.TableNav {
  display: flex;
  margin-left: -5rem;
  padding-left: 5rem;
  border-bottom: 0.1rem solid var(--tme-grey-5-greys);
  justify-content: space-between;
  margin-top: 5rem;
}

.TableNav2 {
  display: flex;
  margin-left: -3rem;
  padding-left: 3rem;
  border-bottom: 0.1rem solid var(--tme-grey-5-greys);
  justify-content: space-between;
  margin-top: 5rem;
}

.NavItem {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  padding-bottom: 1.4rem;
  cursor: pointer;
}

.NavItemSelected {
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-right: 4rem;
  border-bottom: 2px solid var(--toyota-red-primary);
  color: var(--toyota-red-primary);
  padding-bottom: 1.4rem;
  cursor: pointer;
}

.Buttons {
  align-self: flex-end;
  margin-top: 10rem;

}

.floatcontainer {
  border: 3px solid #fff;
}

.floatchild {
  width: 50%;
  float: left;
  padding: 0px;
  margin-bottom: 5rem;
}

.floatchild1 {
  width: 30%;
  float: left;
  padding-right: 10px;
  margin-bottom: 5rem;
}

.floatchild2 {
  width: 70%;
  float: left;
  padding: 0px;
  margin-bottom: 5rem;
}

.floatchild3 {
  width: 32%;
  float: right;
  padding: 0px;
  margin-bottom: 5rem;
}

.floatchild4 {
  width: 32%;
  float: right;
  padding: 0px;
  margin-bottom: 5rem;
  margin-right: 16rem;
}

.saveclose {
  width: 32%;
  float: right;
  margin-top: -18.2rem;
}

.Vinsave {
  float: right;
  margin-right: 22rem;
}

.Vinsaveclose {
  float: right;
  margin-right: -33rem;
}

.DeleteVin {
  margin-top: 0.7rem;
}

.publish {
  width: 32%;
  float: right;
  margin-top: -8.2rem;
  margin-right: -18.3rem;
}

.VinListDeleteDownload {
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  cursor: pointer;
}

.VinListDeleteDownloadView {
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  opacity: 0.7;
  pointer-events: none;
}

.ParentContainer {
  display: flex;
}

.ParentContainer label {
  font-size: 1.6rem;
}

.child1 {
  flex: 1;
}