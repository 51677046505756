.VersionControlModule {
  background-color: var(--app-white);
  height: calc(100% - var(--content-header-height) + 1rem);
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  padding: 3.1rem 0;
}

.PrimaryLabel {
  line-height: 2.4rem;
  font-size: 1.6rem;
  color: var(--grey-primary);
}

.SecondaryLabel {
  line-height: 2.4rem;
  font-size: 1.2rem;
}

.VersionControlTableHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* padding-left: 4rem; */
  width: 94.3rem;
  height: 5.2rem;
}

.Group1 {
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 0rem 3.2rem; */
  margin-right: 3.2rem;
  padding-left: 4rem;
  text-align: left;
  font-family: 'Toyota Type Regular';
}

.Separator {
  align-self: stretch;
  background-color: var(--tme-grey-5-greys);
  margin: 0 1rem;
  width: 0.1rem;
  /* margin: 0 4rem; */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0rem;
}

.Group2 {
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0rem 3.2rem;
  text-align: left;
  font-family: 'Toyota Type Regular';
}

.Group3 {
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0rem 3.2rem;
  text-align: left;
  font-family: 'Toyota Type Regular';
}
