.CreateContent {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}
.CreateContentModule {
  background-color: var(--app-white);
  min-height: calc(176rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  /* padding: 3.1rem 0; */
  display: grid;
  grid-auto-columns: 20rem 1fr;
  grid-template-areas: "LeftNav RightContent";
}
.CreateContentModule1 {
  background-color: var(--app-white);
  min-height: calc(190rem - var(--content-header-height) + 1rem);
  height: auto;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  padding: 3.1rem 0 0 3rem;
}

.RightContentEditCopy {
  grid-column: 2;
  padding-top: 2rem;
  /* border: 1px solid black; */
  /* padding-left: 5rem; */
  padding-right: 4rem;
  text-align: left;
}

.LeftContent {
  grid-column: 1;
  /* border: 1px solid black; */
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.RightContent {
  grid-column: 2;
  padding-top: 2rem;
  /* border: 1px solid black; */
  padding-left: 5rem;
  padding-right: 4rem;
  text-align: left;
}

.NavItem {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavItemSelected {
  background-color: rgba(77, 187, 241, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.CreateCustomerContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 45.9rem 1fr;
  grid-template-areas:
    "UpperContent"
    "LowerContent";
}

.UpperContent {
  background-color: var(--app-white);
  /* border: 1px solid black; */
}

.LowerContent {
  /* border: 1px solid black; */
  background-color: var(--app-white);
  min-height: 110rem;
}

.NodeItem {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}
/* styles related to newContent Page*/
.UpdateFormHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
  margin-top: -1rem;
}
.UpdateFormSubTitle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #282830;
}
.ChannelContainer {
  margin-top: 3.5rem;
  text-align: left;
  padding: 0 0rem;
}
.Input {
  min-width: 15rem;
  margin-top: 1rem;
}
.Discard {
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 12rem;
}
.Buttons {
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 12rem;
  margin-left: 60rem;
}

.CreateContent label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}
.Container {
  display: flex;
  padding-left: 13rem;
}
.CreateContent label span {
  color: red;
  margin:0.3rem;
}

.CalendarSelector {
  margin-top: 1rem;
  width: 50%;
  height: 3.8rem;
}

.CreateContent label date {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.LabeledSelect {
  margin-top: 2rem;
}

.LabeledDescription {
  margin-left: -1rem;
}

.HorizontalSeperator {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 1px solid var(--tme-grey-4-greys);
}

.CreativeToggle {
  font-family: "Toyota Type Book";
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 2rem;
}

.TextDisabled {
  color: var(--tme-grey-4-greys) !important;
}

/* Edit Copy Styles*/

.CollapseContentSpace {
  margin-top: 3rem;
}
.CollapseContentTitle {
  width: 95%;
  margin-left: 0.2rem;
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}
.CollapseContentSubTitle {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000000;
  margin-bottom: 1rem;
}
.CollapseContentDescription {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #282830;
}

.EditDescription {
  text-align: left;
  margin: 4rem 0 0 3.6rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;
  font-family: "Toyota Type Regular";
}
.EditDescriptionContent {
  text-align: left;
  /* margin: 4rem 0 0 3.6rem; */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;
  font-family: "Toyota Type Book";
}

.Separator {
  align-self: stretch;
  background-color: var(--tme-grey-5-greys);
  margin: 0 4rem;
  width: 0.1rem;
}

.EditBrief {
  text-align: left;
  margin: 1rem 0 0 2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.8rem;
  font-family: "Toyota Type Regular";
}
.hr {
  border: 1px solid #cecfd0;
  margin: 4rem 0 2rem 0 !important;
  height: 0rem;
}

.VersionControlModule {
  background-color: var(--app-white);
  min-height: calc(100% - var(--content-header-height) + 1rem);
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  padding: 3.1rem 0;
}

.TableRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: flex-start;
}

.TableCol {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.TableRow1 {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

.TableRow2 {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1 1;
  align-items: center;
  margin: 2rem 0 0 2rem;
}

.TableRow3 {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1 1;
  align-items: center;
  margin: 2rem 0 0 2rem;
}

.Box1 {
  margin-left: 3.6rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.ColTitle {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-family: "Toyota Type Regular";
}

.DesignFileButton {
  width: 20rem;
  background: #cecfd0;
  margin-left: 12rem;
  border: none;
  color: white;
  font-size: 1.6rem;
  line-height: 3.4rem;
}
.CopywriteFileButton {
  width: 18rem;
  margin-left: 12rem;
}

.Activity {
  display: flex;
  flex-basis: 100%;
  flex: 1;
  margin: 2rem 0 0 3.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: normal;
  align-items: flex-start;
}

.TrailItem {
  order: 0;
  flex-basis: 10%;
  z-index: 1;
  margin-bottom: 3rem;
}

.downloadBtn {
  background: #5592ff;
  color: var(--app-white);
  font-size: 1.4rem;
  width: 22.8rem;
  height: 4.2rem;
  padding: 0.2rem;
  border-radius: 0.3rem;
  border-color: rgba(1, 1, 1, 0);
  outline: none;
  cursor: pointer;
}

.downloadBtnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5rem;
}

.Textbox {
  min-width: 98%;
  min-height: 5.6rem;
  border: 1px solid var(--tme-grey-5-greys);
  resize: none;
  font-family: "Toyota Type Book";
  font-size: 1.3rem;
  padding-top: 1.5rem;
  border-radius: 0.5rem;
}

::placeholder {
  color: #8b8c90;
  opacity: 1; /* Firefox */
}

.Textbox:focus {
  outline: none;
}

.NewCommentContent {
  font-family: "Toyota Type Regular";
  font-size: 1.2rem;
  display: flex;
  margin-left: 2rem;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
}

.NewCommentContentDate {
  text-align: left;
  font-family: "Toyota Type Book";
  font-size: 1.2rem;
  display: flex;
  margin-bottom: 3rem;
  margin-left: 2rem;
}
.NewCommentContentDate1 {
  text-align: left;
  font-family: "Toyota Type Book";
  font-size: 1.2rem;
  display: flex;
  margin-bottom: 3rem;
}

.NewCommentContentTrailLine {
  position: relative;
  width: 95px;
  height: 1px;
  /* padding-left: 9rem; */
  padding-bottom: -2rem;
  left: 151px;
  top: 780px;
  /* top: -40px; */
  border-top: 1px solid #5592ff;
  background-color: #5592ff;
  transform: rotate(90deg);
}

.hrVertical {
  border: 1px dashed #cecfd0;
  max-height: 100vh;
  margin: 0 0 1.5rem 0.5rem;
  width: 95px;
  height: 0px;
  transform: rotate(90deg);
}

.hrVerticalBlue {
  border: 1px solid #5592ff;
  max-height: 100vh;
  margin: 0 0 1.5rem 0.5rem;
  width: 95px;
  height: 0px;
  transform: rotate(90deg);
}

.Dot {
  height: 2.4rem;
  width: 2.4rem;
  background-color: var(--ct-blue-secondary);
  border-radius: 50%;
  display: inline-block;
  border: var(--ct-blue-secondary) 1px solid;
  z-index: 1;
}

.Circle {
  height: 1.6rem;
  width: 1.6rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #cecfd0;
  z-index: 1;
}

.CircleBlue {
  height: 1.6rem;
  width: 1.6rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #5592ff;
  z-index: 1;
  box-sizing: border-box;
}

.PrimaryLabel {
  line-height: 2.4rem;
  font-size: 2rem;
  color: var(--grey-primary);
}
.PrimaryLabelBrand {
  line-height: 2rem;
  font-family: "Toyota Type Book";
  font-size: 1.6rem;
  color: var(--grey-primary);
}

.SecondaryLabel {
  line-height: 2.4rem;
  font-size: 1.2rem;
}
  .floatcontainer{
    border: 3px solid #fff;
  }
  .floatchild{
    width: 50%;
    float: left;
    padding: 0px;
    margin-bottom: 5rem;
    padding-right: 14rem;
  }
  .floatchild1{
    float: left;
    width: 50%;
    display: flex;
    margin-left: 3rem;
  }
  .floatchild2{
    width: 30%;
    float: left;
    margin-left: 14rem;
  }
  .floatchild3 {
    float: right;
    margin-right: 20rem;
  }
  .floatchild4 {
    float: right;
    margin-top: -4rem;
    margin-left: 70rem;
  }
  .floatchild5 {
    float: right;
    margin-top: 4rem;
    margin-left: 70rem;
  }
  .floatchild6{
    float: left;
    width: 50%;
    display: flex;
    margin-left: 14rem;
  }
  .floatchild7{
    float: left;
    width: 50%;
    display: flex;
    margin-left: 17rem;
  }
  .floatchild8{
    float: left;
    width: 50%;
    display: flex;
    margin-left: 6rem;
  }
.ButtonsEdit{
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 10rem;
  margin-left: 1rem;
}
.EditCreativeBrief{
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 10rem;
  margin-left: 3rem;
}
.NewCreativeBrief{
  align-self: flex-end;
  padding-right: 1rem;
  margin-top: 10rem;
  margin-left: -1rem;
}
.NodeDisplayDisable {
  opacity: 0.7;
  pointer-events: none;
}