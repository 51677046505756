.Container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Header {
  padding: 0 2rem 0 3rem;
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--tme-grey-6-greys);

  margin-bottom: 4rem;
}

.CloseIcon {
  cursor: pointer;
  fill: var(--tme-grey-2-greys);
}

.CloseIcon:hover {
  fill: var(--tme-grey-4-greys);
}

.Header>p {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.Body {
  display: flex;
  align-items: center;
  align-self: center;
}

.Body>p {
  margin-left: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 0rem;
}

.Buttons {
  align-self: flex-end;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.Select {
  width: 90%;
  margin-left: 3rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  /*padding-bottom:3rem;*/
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 40px;
}

.Alert {
  margin-left: 10rem;
  margin-bottom: 5rem;
  margin-top: 2rem;
  font-size: 12px;
  padding-bottom: 3rem;
}

.BrandAndCampaign {
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 2rem;
  padding-left: 3rem;
}

.label span {
  color: red;
}

/* create subcategory styles for campaign*/
.CategorySubCategoryCombo {
  display: flex;
  padding: 0 1rem;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem;
}

.AddSubCat {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-right: 2rem;
  line-height: 30px;
}

.CategorySubCategoryCombo label span {
  color: red;
}

/* create delivery rule styles for campaign*/
.DeliveryRulesCombo {
  margin-top: 3rem;
  display: flex;
  font-size: 1.2rem;
}

.DeliveryRulesCombo label span {
  color: red;
}

.RuleName {
  margin-left: 3rem;
  margin-top: -3rem;
  width: 26rem;
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 40px;
}

.line {
  width: 94%;
  margin-left: 3rem;
  margin-top: 8rem;
  border-bottom: 1px solid lightgray;
  position: absolute;
}

.CampaignCopy {
  width: 26rem;
  margin-left: -26rem;
  margin-top: 12rem;
  font-family: "Toyota Type Regular";
  font-size: 12px;
}

.CustomerList {
  width: 26rem;
  margin-left: 32.5rem;
  margin-top: 12.5rem;
  font-family: "Toyota Type Regular";
  font-size: 12px;
}

.DeliveryParameter {
  width: 26rem;
  margin-left: -26rem;
  margin-top: 11rem;
  font-family: "Toyota Type Regular";
  font-size: 12px;
}

.LogicalConnectors {
  width: 20rem;
  margin-left: 6rem;
  margin-top: 11rem;
  font-family: "Toyota Type Regular";
  font-size: 12px;
}

.NumberOfDays {
  width: 26rem;
  margin-left: 6rem;
  margin-top: 11rem;
  font-family: "Toyota Type Regular";
  font-size: 12px;
}

.DeliveryRulesCombo1 {
  display: flex;
  font-size: 1.2rem;
}

.StartDate {
  margin-left: -84.5rem;
  margin-top: 21rem;
  /* margin-top: 31rem; */
}

.CalendarSelector1 {
  width: 100%;
  height: 3.8rem;
}

.EndDate {
  margin-left: 12rem;
  margin-top: 21rem;
  /* margin-top: 31rem; */
}

.CalendarSelector2 {
  width: 100%;
  height: 3.8rem;
}

.WarningMessage {
  margin-top: -1rem;
  margin-left: 4rem;
  color: red;
  font-size: 1.6rem;
}

.WarningMessage2 {
  margin-top: -1rem;
  margin-left: 3rem;
  color: red;
  font-size: 1.6rem;
}