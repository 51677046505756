.Container {
    margin-top: 2rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
}

.ContainerNoPadding {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.containerTextArea {
    width: 100%;
    height: 8rem;
    border-radius: .4rem;
    border: .1rem solid var(--tme-grey-5-greys);
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: "Toyota Type Regular", sans-serif;
    padding: 1rem 2rem;

    resize: none;
}

.Container textarea:focus {
    outline: none;
}