.CreateCampaign {
  overflow-y: auto;
  overflow-x: hidden;
}

.CreateCampaign label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.CreateCampaign label span {
  color: red;
}

.BrandAndCampaign {
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.BrandCombo {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.Brand {
  min-width: 20rem;
  height: 3.8rem;
}

.CampaignNameCombo {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.CampaignNameBox {
  height: 3.8rem;
  border: 0.1rem solid var(--tme-grey-5-greys);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-left: 1rem;
}

.CampaignNameBox:focus {
  outline: none;
}

.ChannelContainer {
  margin-top: 3.5rem;
  text-align: left;
  padding: 0 1rem;
}

.CategorySubCategoryCombo {
  margin-top: 3rem;
  display: flex;
  padding: 0 1rem;
  align-items: flex-end;
  justify-content: space-between;
}

.EnableAdHocTransactional {
  margin-top: 2rem;
  padding: 0 1rem;
  text-align: right;
}

.transactionalCheckbox {
  width: 20px;
  height: 20px;
  vertical-align:middle;
  margin-top: -0.3rem;
  margin-left: 1rem;
  text-align: right;
}

.AdhocDatesCombo {
  margin-top: 2rem;
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  flex-direction: column;
}

.AdhocDatesCombo1 {
  margin-top: 2rem;
  display: flex;
  padding: 0 1rem;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  grid-column: 1 / span 3;
}

.AdhocDatesCombo_quotaException1 {
  margin-top: 2rem;
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  flex-direction: column;
}

.AdhocDatesCombo_quotaException2 {
  margin-top: 2rem;
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  flex-direction: row;
  width: 50%;
}

.Select {
  width: 45%;
}

.CreateCustomerBlock {
  display: flex;
}

.TemplateContainer {
  display: flex;
  flex-direction: column;
}

.Approval {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "CreativeApproval ApprovalProof"
    "DivisionApproval DivisionApprovalUploads"
    "LegalApproval LegalApprovalProof";
  /* align-items: end; */
  padding: 1rem 2rem;
}
.innerGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.innerGrid > div {
  background: lightgreen;
  padding: 8px;
}

.Approval label {
  margin-bottom: 0;
}

.Adhoc {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  padding: 1rem 2rem;
  border: 0.1rem solid var(--tme-grey-5-greys);
}

/* .Adhoc label {
  margin-bottom: 0;
} */

.CreativeApproval {
  grid-area: CreativeApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.disableAdhoc {
  pointer-events: none;
  color: darkgrey;
}

.enableAdhoc {
  cursor: pointer;
}

.DivisionApproval {
  grid-area: DivisionApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.LegalApproval {
  grid-area: LegalApproval;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
}

.ApprovalProof {
  grid-area: ApprovalProof;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.DivisionApprovalUploads {
  grid-area: DivisionApprovalUploads;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.LegalApprovalProof {
  grid-area: LegalApprovalProof;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}

.CalendarSelector {
  margin-top: 1rem;
  width: 100%;
  height: 3.8rem;
}

.CustomerListAndDeliveryRulesContainer {
  margin-top: 3.5rem;
  text-align: left;
  padding: 0 1rem;
}

.CustomerListAndDeliveryRulesContainer>div {
  margin-bottom: 2rem;
}

.Buttons {
  align-self: flex-end;
  padding-right: 1rem;
}

.ViewNotice {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: white;
  background-color: var(--toyota-red-primary);
}

.SaveAndClose {
  float: left;
  margin-top: -7rem;
}
.SaveCampaign{
  float:right;
  margin-top:-7rem;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 4;
}

.plusSymbol {
  margin-left: 3rem;
  margin-top: 4.6rem;
}

.minusSymboList {
  margin-left: 3rem;
  margin-top: 4.6rem;
}

.minusSymbol {
  margin-left: 3rem;
  margin-top: 2.7rem;
}

.suggestion {
  cursor: pointer;
  padding: 0.9rem;
  font-size: 1.4rem;
}

.suggestion:hover {
  background-color: #dae4f4;
}

.deliverRuleLabel {
  float: right;
  cursor: pointer;
}

.deliverRuleInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ParentContainer{
  display:flex;
  align-items: flex-end;
  padding-right:9rem;
}
.child1 {
  flex: 1;
}
/*Edit Campaign download*/
.CampaignFileDownload{
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  }
  
.DisableCalendarFile{
  opacity: 0.7;
  pointer-events: none;
  width: 100%;
}
.EnableCalendarFile{
  width: 100%;
}
.DisableCampaign{
  opacity: 0.7;
  pointer-events: none;
}
.SubCategoryDropDown{
  width:100%;
  border-radius: 4px;
 font-size: 1.2rem;
 color:black;
 display:"flex";
 flex-direction:"column";
 /* width:"45%"; */
}
.DisableImagePreview{
  pointer-events: none;
  opacity: 0.5;
}

.DisableTemplate{
  pointer-events: none;
  width:45%;
  opacity: 0.5;
}

.DisableCopy{
  pointer-events: none;
  width:100%;
  opacity: 0.5;
}
.DisableLabel{
  pointer-events: none;
  opacity: 0.5;
}
.BigSvg {
  height: 30%;
  width: 30%;
  padding-left: 35rem;
}