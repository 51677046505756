.VerCtrlHdr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    height: var(--content-header-height);
}

.VerCtrlHdrLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.VerCtrlHdrRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Separator {
    align-self: stretch;
    background-color: var(--tme-grey-5-greys);
    margin: 0 1rem;
    width: .1rem;
}

.SearchIcon {
    width: 2rem;
    height: 2rem;
    fill: var(--tme-grey-2-greys);
}

.SearchIcon:hover {
    fill: var(--tme-grey-3-greys);
}

.Search {
    padding: 1rem;
    outline: none;
    border: none;
    background: var(--ct-background-grey);
    font-size: 1.4rem;
    line-height: 2.4rem;
}

.SearchBarContainer {
    display: flex;
    align-items: center;
}