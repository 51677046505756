.Container {
    margin-top: 2rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
}

.Div {
    border: .1rem solid var(--tme-grey-5-greys);
    border-radius: .4rem;
}