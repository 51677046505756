.SidePageContent {
  background-color: var(--app-white);

  height: calc(100%);
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* margin-bottom: 2rem; */
  /* padding: 2rem; */
}
