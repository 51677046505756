.ItemHistory {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 0 1;
  padding: 1.5rem;
}
.DotActionButton {
  background: #6F7070;
  width: .3rem;
  height: .3rem;
  /* border-radius: .15rem; */
}
.ActionContainer {
  background: #F2F2F2;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 1.2rem;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-width: 0;
  outline: none;
}

.ActionContainer:hover {
  cursor: pointer;
  /* background: var(--hover-red-primary); */
}

.TrailItem {
  order: 0;
  flex-grow: 1;
  flex-basis: 20%;
  z-index: 1;
}

.Dot {
  height: 2.4rem;
  width: 2.4rem;
  background-color: var(--ct-blue-secondary);
  border-radius: 50%;
  display: inline-block;
  border: var(--ct-blue-secondary) 1px solid;
  z-index: 1;
}

/* .TrailItem::after{
  border-left:2px solid green;
  height: 500px;
  content: "";
  position: absolute;
  left: 10rem;
} */

.Circle {
  height: 1.6rem;
  width: 1.6rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: var(--ct-blue-secondary) 1px solid;
  z-index: 1;
  background-color: var(--app-white);
}

.VersionDateTime {
  order: 1;
  flex-grow: 1;
  flex-basis: 60%;
  text-align: left;
}

.Version {
  font-family: Toyota Type Semibold;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--ct-blue-secondary);
  text-decoration: none;
}

.Date {
  font-family: Toyota Type Regular;
  font-size: 1.2rem;
}

.Status {
  order: 2;
  flex-grow: 1;
  flex-basis: 20%;
}

.StatusLabel {
  font-family: Toyota Type Regular;
  font-size: 12px;
  text-align: Center;
}

.Draft {
  background-color: var(--ct-yellow-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GoneLive {
  background-color: var(--ct-orange-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CurrentVersion {
  background-color: var(--ct-green-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reused {
  background-color: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Paused{
  background: var(--ct-orange-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Testing{
  background: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Live{
  background: var(--ct-green-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CAMPAIGN_REQUESTED{
  background: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CAMPAIGN_APPROVED{
  background: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CREATIVE_APPROVED{
  background: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CREATIVE_REQUESTED{
  background: var(--ct-blue-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.APPROVED{
  background: var(--ct-green-light-secondary);
  min-height: 2.9rem;
  min-width: 10.8rem;
  border-radius: 0.4rem;
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TrailLine {
  position: relative;
  width: 72px;
  height: 1px;
  left: 45px;
  top: -13px;
  border-top: 1px solid #5592ff;
  background-color: #5592ff;
  transform: rotate(90deg);
}
