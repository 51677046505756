.SelectorBoxContainer {
    position: relative;
    display: inline-block;
    background-color: var(--app-white);
    border-radius: .4rem;
    text-align: left;
    border: .1rem solid var(--tme-grey-5-greys);
}

.SelectBox {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding: .7rem 1rem;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.SelectBox:focus {
    outline: 0;
    box-shadow: 0 0 .4rem #0493d1;
}

.SelectBox::selection {
    background: none;
}

.SelectBox::before {
    content: ' ';
    z-index: 1;
    position: absolute;
    height: 2.4rem;
    top: .7rem;
    right: 3.4rem;
    border-left: .1rem solid var(--tme-grey-5-greys);
}

.SelectBox::after {
    content: url("../../../assets/icons/chevron-down.svg");
    position: absolute;
    z-index: 1;
    top: .8rem;
    right: .5rem;
}

.Small {
    width: 45rem;
}

.Normal {
    width: var(--width-small-screen);
}

.ContentModal {
    height: 60rem;
    padding: 2rem 4rem;
    outline: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--app-white);
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: 110em) {
    .ContentModal {
        width: var(--width-small-screen);
    }
    .Small {
        width: 40rem;
    }
}

.ContentModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
}