.Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 70%;

}

.Selector {

    border: 1px solid #D1D1D1;
    padding: .5rem;
    outline: none;
    height: 4rem;
    width: 90%;
}

.Label {
    margin-left: .15rem;
    margin-bottom: .75rem;
    line-height: 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
}