.NavLink {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
}

.NavLink.NavLinkSelected {
    background: var(--toyota-red-primary);
}

.NavLabel {
    margin-left: .5rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
}

.NavLabel.NavLabelSelected {
    color: white;
}

.Icon {
    fill: black;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
}

.Icon.IconSelected {
    fill: white;
}