.FilterModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
}

.FilterModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(85rem, 80vw, 55vw);
    height: clamp(30rem, 40vw, 25rem);
    background: var(--app-white);
    padding: 3rem 4rem;
    outline: none;
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.Filter {
    margin-left: 1rem;
    outline: none;
    height: 3.8rem;
    width: 10.2rem;
    border-radius: .4rem;
    border: 1px solid var(--tme-grey-5-greys);
    background: var(--app-white);
    color: var(--grey-primary);
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.FilterSelected {
    margin-left: 1rem;
    outline: none;
    height: 3.8rem;
    width: 10.2rem;
    border-radius: .4rem;
    border: 1px solid var(--tme-grey-5-greys);
    background: #6A6B70;
    color: white;
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.Filter:hover {
    border: 1px solid rgb(179, 179, 179);
}

.FilterTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.FilterTitleHeading {
    font-size: 1.6rem;
    color: var(--toyota-red-primary);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FiltersContainer {
    align-self: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 4rem;
    justify-items: stretch;
    align-items: center;
    align-content: start;
}

.Buttons {
    align-self: flex-end;
}

.CloseIcon {
    cursor: pointer;
    fill: var(--tme-grey-2-greys);
}