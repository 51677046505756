.ItemsPerPage {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.PageSizes {
    min-width: 7rem;
    margin-right: 1.5rem;
}

.Label {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
}