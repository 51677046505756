.TableHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    width: 100%;
    padding: 0 2rem;
}

.TableHeadLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    height: 10rem;
    width: 40%;
    padding-bottom: 1rem;
}

.TableHeadRight {
    width: 33.3%;
}

.SortByContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.SortBy {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--grey-primary);
    margin-bottom: 1.25rem;
}

.SortBySelect {
    width: 25rem;
    min-width: 20rem;
    text-align: left;
}