.CreateTemplateTypes {
  height: 100%;
  padding: 1rem 2rem 3rem;
  margin: 0;
}

.CreateTemplateTypes label {
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.5rem;
}

.CreateTemplateTypes label span {
  color: red;
  margin: 0.3rem;
}

.CreateTemplateModule {
  background-color: var(--app-white);
  min-height: calc(165rem - var(--content-header-height) + 1rem);
  height: 450rem;
  border-radius: 0.1rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15);
  display: grid;
  grid-auto-columns: 20rem 1fr;
  grid-template-areas: "LeftNav RightContent";
}

.LeftContent {
  grid-column: 1;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.RightContent {
  grid-column: 2;
  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 4rem;
  text-align: left;
}

.TemplateTypesContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 45.9rem 1fr;
  grid-template-areas:
      "UpperContent"
      "LowerContent";
}

.NavItemSelected {
  background-color: rgba(77, 187, 241, 0.1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.NavItem {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Toyota Type Regular;
  cursor: pointer;
  text-align: left;
  padding-left: 1.7rem;
}

.Select {
  width: 100%;
  margin-top: 3rem;
}

.Input {
  width: 100%;
  margin-top: 3rem;
}

.UpdateFormHeader {
  font-family: "Toyota Type Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #282830;
}

.UpdateFormHeader span {
  color: red;
}

.Container {
  display: flex;
  flex-direction:row;
  margin-top: 3rem;
}

.TemplateDeleteDownload {
  font-family: 'Toyota Type Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #5592FF;
  cursor: pointer;
}

.btnDownload{
  display: flex;
  margin-top: 3rem;
}

.btnDelete {
  margin-left: 33rem;
}

.savediscardcontainer {
  padding: 20px;
  width: 50%;
  float: right;
  margin-top: 11rem;
}

.discardchild {
  width: 25%;
  float: left;
  padding: 20px;
}

.savechild {
  width: 25%;
  float: right;
  padding: 20px;
}

.savediscardcontainer2 {
  padding: 20px;
  width: 50%;
  float: right;
  margin-top: 10rem;
  margin-right: -17rem;
}

.line {
  width: 100%;
  margin-top: 0.5rem;
  border-bottom: 2px solid lightgray;
}