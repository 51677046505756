.Content {
    background-color: var(--app-white);
    height: calc(100vh - var(--app-header-height) - 4rem);
    padding: 1rem 0;
    min-height: 45rem;
}

.ContentContainer {
    background-color: var(--ct-background-grey);
    min-height: 100%;
}