.ActionContainer {
    background: #F2F2F2;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 1.2rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-width: 0;
    outline: none;
}

.ActionContainer:hover {
    cursor: pointer;
    background: var(--hover-red-primary);
}

.VersionActionContainer {
    background: #F2F2F2;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 1.2rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-width: 0;
    outline: none;
}

.VersionActionContainer:hover {
    cursor: pointer;
    background: var(--hover-red-primary);
}

.Dot {
    background: #6F7070;
    width: .3rem;
    height: .3rem;
    /* border-radius: .15rem; */
}

.ActionContainer:hover .Dot {
    background: var(--app-white);
}

.MenuContainer {
    background: var(--app-white);
    border-radius: 0 0 .4rem .4rem;
    width: 12.2rem;
    min-height: 13.2rem;
    box-shadow: 0 .4rem 1.2rem rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.MenuItem {
    min-height: 3.2rem;
    width: 100%;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: .8rem;
    padding-top: .5rem;
}

.MenuItem::selection {
    background: none;
}

.MenuItem:hover {
    background: var(--ct-blue-secondary-op-light);
    cursor: pointer;
}

.MenuItem:hover:first-child {
    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
}

.MenuItem:hover:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}