.VersionControlModule {
  /* background-color: var(--app-white); */
  height: calc(100% - var(--content-header-height) + 1rem);
  /* border-radius: 0.5rem; */
  /* box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.15); */
  /* padding: 3.1rem 0; */
}

.DisplayLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -3rem;
  border-top: 1px solid var(--tme-grey-5-greys);
}

.DetailsDisplay {
  background-color: var(--app-white);
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 88%;
  padding-top: 2.4rem;
  padding-left: 2.3rem;
  border-right: 1px solid var(--tme-grey-5-greys);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.PreviewDisplay {
  width: 100%;
  height: 100%;
  background-color: var(--tme-grey-6-greys);
}

.PreviewContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 3rem;
}

.PreviewImage {
  height: 67.6rem;
  width: 34rem;
  /* border: 1px solid black; */
}

.ArrowContainer {
  height: 4rem;
  width: 4rem;
  background-color: var(--app-white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
}

.LeftArrow {
  margin: auto;
}

.RightArrow {
  margin: auto;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.PreviewTemplateName {
  font-size:  1.3rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
  padding-top: 2rem;
  padding-bottom: 3.6rem;
}

.VersionLabel {
  line-height: 2.8rem;
  font-size: 2rem;
  color: var(--ct-blue-secondary);
  font-family: Toyota Type Book;
}

.TemplateTitle {
  line-height: 2rem;
  font-size: 1.6rem;
}

.EditDate {
  line-height: 2rem;
  font-size: 1.2rem;
}

.SmallSeparator {
  width: calc(100% + 2.3rem);
  margin-left: -2.3rem;
  border-top: 1px solid var(--tme-grey-5-greys);
  /* height: 1px; */
  margin-top: 2.4rem;
  margin-bottom: 2.2rem;
}

.Comments {
  text-align: left;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: Toyota Type Book;
  padding-bottom: 2rem;
}

.CommentBy {
  color: var(--tme-grey-3-greys)
}