.AddRuleButton {
  height: 3.2rem;
  width: 16rem;
  left: 88.5rem;
  top: 22rem;
  border-radius: 0.4rem;
  padding: 4px, 0px, 4px, 0px;
  margin-top: 2rem;
  background-color: var(--ct-blue-secondary);
  border-color: var(--ct-blue-secondary);
}

.AddNodeButton {
  border-radius: 0.4rem;
  background-color: var(--toyota-red-primary);
  border-color: var(--toyota-red-primary);
}

.AddNodeButton:hover {
  background-color: var(--hover-red-primary);
}

.DiscardNodesButton {
  border-radius: 0.4rem;
  background-color: white;
  color: var(--toyota-red-primary);
  border-color: var(--toyota-red-primary);
}

.DiscardNodesButton:hover {
  background-color: var(--hover-red-primary);
  color: white;
}

.orDivider {
  padding-top: 1.3rem;
}

.PopoverTitle {
  font-size: large;
}

.Title {
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.Block {
  height: 5rem;
  margin-top: 1.8rem;
  background: #f5f6fa;
}

.Dashed {
  width: 11.1rem;
  height: 0;
  border: 1px solid #c4c4c4;
  margin: 0.3rem;
  display: inline-block;
}

.Block {
  font-family: Toyota Type Regular;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #282830;
  padding: 1.2rem;
  margin-left: -0.8rem;
  margin-right: -0.7rem;
}

.SelectRuleForm {
  width: 260px;
  height: 40px;
  font-size: 14px;
}

.NodeDisplay {
  display: grid;
  /* grid-template-columns: repeat(1, 1fr); */
  grid-auto-columns: 90rem 1fr;
  /* grid-auto-rows: 45.9rem 1fr; */
  grid-template-areas: "NodeContainer RuleContainer";
  min-height: 70rem;
  margin-left: -4rem;
}

.NodeDisplayDisable {
  display: grid;
  /* grid-template-columns: repeat(1, 1fr); */
  grid-auto-columns: 90rem 1fr;
  /* grid-auto-rows: 45.9rem 1fr; */
  grid-template-areas: "NodeContainer RuleContainer";
  min-height: 70rem;
  margin-left: -4rem;
  opacity: 0.7;
  pointer-events: none;

}

.NodeContainer {
  display: grid;
  grid-template-areas: "NodesLeft NodesRight";
  min-height: 70rem;
}

.RuleContainer {
  text-align: center;
  margin-right: 5rem;
}

.RuleContainerEdit {
  text-align: center;
  margin-right: -15rem;
}

.NodeItem {
  min-height: 9.5rem;
  min-width: 15rem;
  width: 15rem;
  text-align: center;
  border: 0.1rem solid var(--tme-grey-5-greys);
  margin: 2rem;
  margin-left: 2rem;
}

.NodeItemAnd {
  min-width: 11.5rem;
  width: 11.5rem;
  text-align: center;
  border: 0.1rem solid var(--tme-grey-5-greys);
  margin: 2rem;
  margin-top: 14.2rem;
}

.NodeItemTitle {
  border-bottom: 1px solid var(--tme-grey-5-greys);
  height: 4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 0.5rem;
  background-color: var(--tme-grey-6-greys);
}

.NodeItemTitle:hover {
  background-color: var(--ct-backgound-highlight);
  cursor: pointer;
  padding-top: 0.7rem;
}

.NodeItemTitle:hover span {
  content: url("../../../../assets/icons/Trashcan.svg");
}

.NodeItemConnector {
  border-bottom: 1px solid var(--tme-grey-5-greys);
  height: 4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 0.5rem;
  background-color: var(--tme-grey-6-greys);
}

.NodeItemContent {
  height: 4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 0.5rem;
}

.NodesLeft {}

.NodesRight {}

.NavItemDisabled {
  border-bottom: 1px solid var(--tme-grey-5-greys);
  height: 4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-top: 0.5rem;
  background-color: var(--tme-grey-6-greys);
  cursor: pointer;
  pointer-events: none;
  color: darkgrey;
}