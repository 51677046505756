.Container {
  min-width: 20rem;
  display: flex;
  flex-direction: column;
}

.Input {
  height: 3.8rem;
  border: 0.1rem solid var(--tme-grey-5-greys);
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-left: 1rem;
}
::placeholder {
  color: #8b8c90;
  font-size: 1.3rem;
  font-family: "Toyota Type Book";
  font-style: normal;
  font-weight: normal;
}
