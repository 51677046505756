.Status {
    border-radius: .3rem;
    padding: .7rem 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.38rem;
    width: 8.9rem;
    height: 2.9rem;
}

.Blue {
    background: var(--ct-blue-light-secondary);
}

.Yellow {
    background: var(--ct-yellow-light-secondary);
}

.Orange {
    background: var(--ct-orange-light-secondary);
}

.Green {
    background: var(--ct-green-light-secondary);
}

.Red {
    background: var(--ct-red-light-secondary);
}