.Container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.Label {
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--tme-grey-1-greys);
}

.Icon {
    fill: var(--tme-grey-3-greys);
}