.TeamCellContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 6.4rem;
    padding: 1rem 0;
}

.TeamItem {
    font-size: 1.2rem;
    line-height: 2.4rem;
}