.FilterModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
}

.FilterModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--width-large-screnn);
    height: calc(var(--width-large-screnn) * 1 / 2);
    background: var(--app-white);
    padding: 3rem 2rem;
    outline: none;
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: 100em) {
    .FilterModal {
        width: var(--width-small-screen);
        height: calc(var(--width-small-screen) * 1 / 2.5);
    }
}

.Filter {
    margin-left: 1rem;
    outline: none;
    height: 3.8rem;
    width: 10.2rem;
    border-radius: .4rem;
    border: 1px solid var(--tme-grey-5-greys);
    background: var(--app-white);
    color: var(--grey-primary);
    font-size: 1.2rem;
    line-height: 2.4rem;

}

.FilterSelected {
    margin-left: 1rem;
    outline: none;
    height: 3.8rem;
    width: 10.2rem;
    border-radius: .4rem;
    border: 1px solid var(--tme-grey-5-greys);
    background: #6A6B70;
    color: white;
    font-size: 1.2rem;
    line-height: 2.4rem;

}

.Filter:hover {
    border: 1px solid rgb(179, 179, 179);
}

.FilterTitleHeading {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--toyota-red-primary);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.FiltersContainer {
    align-self: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-areas:
            "categories sub-categories status"
            "brand date-range channels"
            "creator . ."; */
    grid-template-areas:
        "status date-range channels"
        "creator . ."
        ". . .";

    column-gap: 3rem;
    row-gap: 4rem;
    justify-items: stretch;
    align-items: center;
    align-content: start;
}

.Categories {
    grid-area: categories;
}

.SubCategories {
    grid-area: sub-categories;
}

.Status {
    grid-area: status;
}

.Brand {
    grid-area: brand;
}

.DateRange {
    grid-area: date-range;
}

.Channels {
    grid-area: channels;
}

.Creator {
    grid-area: creator;
}

.ApplyDiscardButtonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    align-self: flex-end;
    margin-top: 3rem;
    flex-basis: 30%;
}

.ApplyDiscardButtonContainer .DiscardButton,
.ApplyButton {
    border-radius: .4rem;
    outline: none;
    padding: .4rem .8rem;
    width: calc(.15 * var(--width-large-screnn));
    height: calc(.05 * var(--width-large-screnn) * 11 / 20);
    min-height: 2.6rem;
    margin-bottom: 1rem;

}

@media only screen and (max-width: 100em) {

    .ApplyDiscardButtonContainer .DiscardButton,
    .ApplyButton {
        width: calc(.15 * var(--width-small-screen));
        height: calc(.05 * var(--width-small-screen) * 11/20);
    }
}

.DiscardButton {
    border: .1rem solid var(--toyota-red-primary);
    background: var(--app-white);
    color: var(--toyota-red-primary);
    margin-right: 1rem;
}

.ApplyButton {
    background: var(--toyota-red-primary);
    color: var(--app-white);
    border: .1rem solid transparent;
}

.ApplyButton:hover,
.DiscardButton:hover {
    background-color: var(--hover-red-primary);
    color: var(--app-white);
}

.SelectBox {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding: .7rem 0.3rem;
    outline: none;
    cursor: pointer;
    text-align: left;
}

.CloseIcon {
    cursor: pointer;
    fill: var(--tme-grey-2-greys);

}

.FilterTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.FilterTitleHeading {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--toyota-red-primary);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    height: 5rem;
    width: 100%;
    align-items: center;
}