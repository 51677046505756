.TemplateDetails {
  min-width: 20rem;
  min-height: 24.5rem;
  max-width: 20rem;
  max-height: 24.5rem;
  margin-right: 3.2rem;
  margin-bottom: 3.2rem;
}

.TemplatePreview {
  content: "";
  background-color: var(--tme-grey-6-greys);
  border: 1px solid var(--tme-grey-4-greys);
  text-decoration: none;
  max-width: 13rem;
  height: 13.2rem;
  padding-left: 2rem;
  padding-top: 1.5rem;
}

.ZoomSvg {
  margin-left: 9rem;
  margin-top: -1rem;
}

.TemplateBigPreview {
  height: 67.6rem;
  width: 34rem;
  margin-left: 2.5rem;
}

.TemplateGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 3rem;
}

.SmallSvg {
  height: 10rem;
  width: 7rem;
  margin-left: 1rem;
}

.BigSvg {
  height: 65rem;
  width: 72rem;
}