.ItemHistory {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 0 1;
  padding: 1.5rem;
}

.TrailItem {
  order: 0;
  flex-grow: 1;
  flex-basis: 0;
  z-index: 1;
}

.Circle {
  height: .8rem;
  width: .8rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: var(--ct-blue-secondary) 1px solid;
  background-color: var(--app-white);
  z-index: 1;
}

.CommentContent {
  order: 1;
  flex-grow: 1;
  flex-basis: 60%;
  text-align: left;
}

.TrailLine {
  position: relative;
  width: 64px;
  height: 1px;
  left: 49px;
  top: -5px;
  border-top: 1px solid #5592ff;
  background-color: #5592ff;
  transform: rotate(90deg);
}
